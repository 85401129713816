import React from 'react';
import useTorrent from '../hooks/useTorrent';

const TorrentPlayer = ({
  torrentId,
  setProgress,
  setDownloadSpeed,
  setUploadSpeed,
  setNumPeers,
  setRemaining,
  setDownloadedBytes,
  setTotalBytes,
}) => {
  const { isLoading, videoRef } = useTorrent(
    torrentId,
    setProgress,
    setDownloadSpeed,
    setUploadSpeed,
    setNumPeers,
    setRemaining,
    setDownloadedBytes,
    setTotalBytes,
  );

  return (
    <div className="torrent-player-container">
      {isLoading && <div className="loading-container">Loading...</div>}
      <video ref={videoRef} width="100%" controls />
    </div>
  );
};

export default TorrentPlayer;
