import React, { useState, useEffect } from 'react';
import { TextField, Tooltip, IconButton, CircularProgress, Box } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';
import { Button } from "./ui/button"

const JoinRoom = () => {
  const navigate = useNavigate();
  const [secureKey, setSecureKey] = useState('');
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const [loading, setLoading] = useState(false);

  const fallbackWordList = [
    'CPU', 'RAM', 'LAN', 'VPN', 'FTP', 'URL', 'DNS', 'USB', 'GUI', 'IOT', 'PHP', 'BOT', 'URL',
  ];

  const apiUrl = process.env.NODE_ENV === 'production' 
    ? 'https://chatvanish.com/api/v1/words' 
    : 'http://localhost:5000/api/v1/words';

  useEffect(() => {
    generateSecureKey();
  }, []);

  const generateSecureKey = async () => {
    try {
      const response = await fetch(`${apiUrl}/generate-key`);
      if (response.ok) {
        const data = await response.json();
        setSecureKey(data.secureKey);
      } else {
        throw new Error('Failed to fetch from API');
      }
    } catch (error) {
      console.error('Error:', error);

      // Generate a secure key using 3 random words from the fallbackWordList
      const randomWords = Array.from({ length: 3 }, () =>
        fallbackWordList[Math.floor(Math.random() * fallbackWordList.length)]
      ).join('-');
      
      setSecureKey(randomWords);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(secureKey);
    setShowCopyTooltip(true);
    setTimeout(() => setShowCopyTooltip(false), 2000);
  };

  const joinSecureChat = () => {
    if (!secureKey) {
      alert('Please enter or generate a Room ID.');
      return;
    }
    setLoading(true);
    navigate(`/chat/${secureKey}`);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="text-center">
        <h1 className="leading leading-tight text-8xl font-light mb-4">
          <span className="block">Join a</span>
          <span className="block">Chat</span>
        </h1>

        <p className="max-w-[750px] text-lg text-muted-foreground sm:text-xl mb-6">
          Enter or share a Room ID
          <span className="block">to start chatting.</span>
        </p>

        <div className="max-w-[300px] mx-auto">
        <Box mt={5}>
          <TextField
            fullWidth
            size="medium"
            value={secureKey}
            onChange={(e) => setSecureKey(e.target.value)}
            label="Room ID"
            variant="outlined"
            aria-label="Room ID Input"
            InputProps={{
              endAdornment: (
                <>
                  <Tooltip title={showCopyTooltip ? 'Copied!' : 'Copy Room ID'}>
                    <IconButton
                      aria-label="Copy Room ID"
                      onClick={copyToClipboard}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Generate New Room ID">
                    <IconButton
                      aria-label="Generate New Room ID"
                      onClick={generateSecureKey}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ),
            }}
          />
        </Box>

        <button
            onClick={joinSecureChat}
            disabled={loading}
            className={`mt-4 w-full inline-flex justify-center items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${loading ? 'opacity-75' : ''}`}
          >
            {loading ? (
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0116 0H4z"></path>
              </svg>
            ) : null}
            Join Room
          </button>
        </div>
      </div>
    </div>
  );
};

export default JoinRoom;