
export const siteConfig = {
  name: "ChatVanish",
  description:
    "ChatVanish: Secure Chat.",
  mainNav: [],
  links: {
    // twitter: "https://twitter.com/shadcn",
    // github: "https://github.com/shadcn/ui",
    // docs: "https://ui.shadcn.com",
  },
}
