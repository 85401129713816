import { useState, useEffect } from 'react';

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const token = document.cookie
        ? document.cookie.split('; ')
            .find(row => row.startsWith('jwt='))
            ?.split('=')[1]
        : null;

      if (token) {
        let URL;
        if (process.env.NODE_ENV === 'production') {
          URL = 'https://chatvanish.com/api/v1/users';
        } else {
          URL = 'http://localhost:5000/api/v1/users';
        }

        try {
          const response = await fetch(URL, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setUser(data); // Set the authenticated user
          } else {
            throw new Error('Failed to fetch user data');
          }
        } catch (error) {
          console.error('Error:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // TODO: use loading in App.js
  return { user, loading };
};

export default useAuth;
