import { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

// Access WebTorrent from the global scope
const WebTorrent = window.WebTorrent;

const { REACT_APP_IS_DEBUG } = process.env;

const debugMode = REACT_APP_IS_DEBUG;
const debugLog = (message) => {
  if (debugMode) {
    console.log(`[DEBUG] ${message}`);
  }
};

// useWebRTC custom hook
export const useWebRTC = (roomCode) => {
  // Initializing refs and state variables
  const socketRef = useRef();
  const peerConnection = useRef();
  const dataChannel = useRef();
  const otherUser = useRef();
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isSendingAttachment, setIsSendingAttachment] = useState(false);
  const secureKeyRef = useRef(roomCode);
  const webTorrentClient = useRef();

  // Using useEffect for component lifecycle management
  useEffect(() => {
    initializeSocketAndPeerConnection();
    setupEventListeners();
    initializeWebTorrentConnection();
    debugLog('Initialization complete');
    return cleanup;
  }, []);

  // Function to initialize socket connection and peer connection
  const initializeSocketAndPeerConnection = () => {
    let SIGNAL_SERVER;
    // Determining the signal server based on the environment
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      SIGNAL_SERVER = 'https://chatvanish.com';
    } else {
      SIGNAL_SERVER = 'http://localhost:5000';
    }
    // Connecting to the signal server and joining the room
    socketRef.current = io.connect(SIGNAL_SERVER);
    debugLog(`Joining room: ${secureKeyRef.current}`);
    socketRef.current.emit('join-room', secureKeyRef.current);

    // Initializing peer connection and setting event handlers
    peerConnection.current = initializePeerConnection();
    peerConnection.current.onicecandidate = handleICECandidateEvent;
    peerConnection.current.ondatachannel = handleIncomingDataChannel;
  };

  // Function to initialize the peer connection with the ICE servers
  const initializePeerConnection = () => {
    const iceServers = [{ urls: 'stun:stun.l.google.com:19302' }];
    return new RTCPeerConnection({ iceServers });
  };

  // Function to setup socket event listeners
  const setupEventListeners = () => {
    socketRef.current.on('other-user', handleOtherUser);
    socketRef.current.on('offer', handleOffer);
    socketRef.current.on('answer', handleAnswer);
    socketRef.current.on('ice-candidate', handleNewICECandidate);
  };

  const initializeWebTorrentConnection = async () => {
    webTorrentClient.current = new WebTorrent();
  };

  // Cleanup function to remove event listeners and close connections
  const cleanup = () => {
    // Removing all event listeners from the socket
    socketRef.current.off('other-user');
    socketRef.current.off('offer');
    socketRef.current.off('answer');
    socketRef.current.off('ice-candidate');

    // Disconnecting the socket and closing the peer connection
    socketRef.current.disconnect();
    if (peerConnection.current) {
      peerConnection.current.close();
    }

    // Cleanup the WebTorrent client
    if (webTorrentClient.current) {
      webTorrentClient.current.destroy((err) => {
        if (err) {
          console.error('Error destroying WebTorrent client:', err);
        }
      });
    }

    // Updating the isConnected state
    setIsConnected(false);
  };

  // Handling incoming data channels
  const handleIncomingDataChannel = (event) => {
    debugLog('Handling Incoming Data Channel');
    dataChannel.current = event.channel;
    dataChannel.current.onmessage = handleIncomingMessage;
    dataChannel.current.onopen = () => {
      debugLog('Data channel is open');
      setIsConnected(true);
    };
    dataChannel.current.onclose = () => {
      debugLog('Data channel is closed');
      setIsConnected(false);
    };
  };

  // Triggered when the local peer generates an ICE candidate.
  // Sends it to the remote peer via the signaling server.
  const handleICECandidateEvent = (event) => {
    if (event.candidate) {
      socketRef.current.emit(
        'ice-candidate',
        otherUser.current,
        event.candidate,
      );
    }
  };

  // Adds the received ICE candidate from the remote peer to the local connection.
  const handleNewICECandidate = (iceCandidate) => {
    peerConnection.current.addIceCandidate(new RTCIceCandidate(iceCandidate));
  };

  // Handling connection with other user
  const handleOtherUser = (userID) => {
    debugLog(`Handling Other User: ${userID}`);
    otherUser.current = userID;

    // Creating a data channel and setting up event listeners
    dataChannel.current = peerConnection.current.createDataChannel(
      'dataChannel',
      {
        ordered: true,
      },
    );
    dataChannel.current.onopen = () => {
      debugLog('Data channel is open');
      setIsConnected(true);
    };
    dataChannel.current.onclose = () => {
      debugLog('Data channel is closed');
      setIsConnected(false);
    };
    dataChannel.current.onmessage = handleIncomingMessage;

    // Creating an offer and setting local description
    createOfferAndSetLocalDescription();
  };

  // Function to create an offer and set local description
  const createOfferAndSetLocalDescription = () => {
    debugLog('Creating Offer');
    peerConnection.current
      .createOffer()
      .then((offer) => peerConnection.current.setLocalDescription(offer))
      .then(() => {
        socketRef.current.emit(
          'offer',
          otherUser.current,
          peerConnection.current.localDescription,
        );
      });
  };

  // Handling offers from other users
  const handleOffer = (offer, userID) => {
    debugLog(`Handling Offer made by userId: ${userID}`);
    otherUser.current = userID;

    // Setting remote description, creating an answer, and updating local description
    peerConnection.current
      .setRemoteDescription(new RTCSessionDescription(offer))
      .then(() => peerConnection.current.createAnswer())
      .then((answer) => peerConnection.current.setLocalDescription(answer))
      .then(() => {
        socketRef.current.emit(
          'answer',
          otherUser.current,
          peerConnection.current.localDescription,
        );
      });
  };

  // Handling answers from other users
  const handleAnswer = (answer) => {
    debugLog(`Handling Answer`);
    peerConnection.current.setRemoteDescription(
      new RTCSessionDescription(answer),
    );
  };

  const createTorrent = (file, callback) => {
    if (webTorrentClient.current) {
      debugLog(`Torrent seeding: ${file.name}`);
      webTorrentClient.current.seed(file, (torrent) => {
        callback(torrent.magnetURI);
      });
    }
  };

  // Modify the downloadTorrent function
  const downloadTorrent = async (messageObject) => {
    webTorrentClient.current.add(messageObject.fileMagnet, async (torrent) => {
      const blob = await torrent.files[0].blob();
      const downloadLink = URL.createObjectURL(blob);

      // Add the file message with the download link to the messages state
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          type: 'file',
          fileData: downloadLink,
          fileType: messageObject.fileType,
          fileName: messageObject.fileName,
          senderId: otherUser.current,
        },
      ]);

      // After adding the file message, send an acknowledgment back to the sender
      sendFileAcknowledgment(otherUser.current, true);

      // Set isSendingAttachment to false to remove the sending status
      setIsSendingAttachment(false);
    });
  };

  const handleIncomingMessage = (event) => {
    const messageData = event.data;

    try {
      const messageObject = JSON.parse(messageData);

      // When the receiver gets an attachment message, setIsSendingAttachment is set to true
      // When the receiver gets an acknowledgment that the sender has received the 'file-acknowledgment',
      // it should set setIsSendingAttachment back to false
      if (messageObject.type === 'attachment') {
        setIsSendingAttachment(true); // Receiver is now downloading the file
      } else if (messageObject.type === 'file-acknowledgment') {
        // This is received from the sender after the receiver sends an acknowledgment
        setIsSendingAttachment(false); // Receiver has acknowledged the receipt
      } else if (messageObject.type === 'typing') {
        setIsTyping(messageObject.data);
      } else if (messageObject.type === 'message') {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: messageObject.data, senderId: 'other' },
        ]);
      } else if (messageObject.type === 'file') {
        downloadTorrent(messageObject); // Receiver starts downloading the file and will send an acknowledgment
      }
    } catch (error) {
      console.error(`Error handling incoming message: ${error}`);
    }
  };

  // Function to send messages
  const sendMessage = (messageText) => {
    const messageObject = { type: 'message', data: messageText };
    dataChannel.current.send(JSON.stringify(messageObject));

    // Updating the messages state
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: messageText, senderId: 'you' },
    ]);
  };

  // Function to send typing events
  const sendTypingEvent = () => {
    if (dataChannel.current) {
      dataChannel.current.send(JSON.stringify({ type: 'typing', data: true }));
    }
  };

  // Function to send stop typing events
  const sendStopTypingEvent = () => {
    if (dataChannel.current) {
      dataChannel.current.send(JSON.stringify({ type: 'typing', data: false }));
    }
  };

  // Function to send attachment event
  const sendAttachmentEvent = () => {
    if (dataChannel.current) {
      dataChannel.current.send(
        JSON.stringify({ type: 'attachment', data: true }),
      );
      setIsSendingAttachment(true);
    }
  };

  // Function to send stop attachment event
  const sendStopAttachmentEvent = () => {
    setIsSendingAttachment(false); // Make sure this is being set to false
  };

  const sendFileAcknowledgment = (senderId, ackStatus) => {
    if (dataChannel.current) {
      const ackMessage = {
        type: 'file-acknowledgment',
        data: ackStatus,
        senderId: senderId,
      };
      dataChannel.current.send(JSON.stringify(ackMessage));
    }
  };

  const handleFileAcknowledgment = (messageObject) => {
    // If the current user is the sender, update the state to reflect the file was received
    if (messageObject.senderId === socketRef.current.id) {
      // assuming socketRef.current.id is the sender's socket ID
      setIsSendingAttachment(false);

      // Update the messages to show that the file was successfully sent
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: `File ${messageObject.fileName} received by ${otherUser.current}.`,
          senderId: 'system',
        },
      ]);
    }
  };

  const sendFile = (file) => {
    if (dataChannel.current) {
      createTorrent(file, (magnetURI) => {
        const fileObject = {
          type: 'file',
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
          fileMagnet: magnetURI,
        };

        debugLog(`Sending file info: ${JSON.stringify(fileObject)}`);
        dataChannel.current.send(JSON.stringify(fileObject));
        sendStopAttachmentEvent(); // This will set isSendingAttachment to false

        setMessages((prevMessages) => [
          ...prevMessages,
          { text: `${file.name} sent successfully`, senderId: 'you' },
        ]);
      });
    }
  };

  // Returning the hook’s API
  return {
    messages,
    sendMessage,
    isTyping,
    sendTypingEvent,
    sendStopTypingEvent,
    isSendingAttachment,
    sendAttachmentEvent,
    sendStopAttachmentEvent,
    handleFileAcknowledgment,
    isConnected,
    sendFile,
  };
};
