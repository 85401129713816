import React, { useState, useEffect, useRef, useCallback } from 'react';

// Function to convert bytes to a readable format
const prettyBytes = (num) => {
  var exponent,
    unit,
    neg = num < 0,
    units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  if (neg) num = -num;
  if (num < 1) return (neg ? '-' : '') + num + ' B';
  exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    units.length - 1,
  );
  num = Number((num / Math.pow(1000, exponent)).toFixed(2));
  unit = units[exponent];
  return (neg ? '-' : '') + num + ' ' + unit;
};

// Custom hook for managing torrent
const useTorrent = (
  torrentId,
  setProgress,
  setDownloadSpeed,
  setUploadSpeed,
  setNumPeers,
  setRemaining,
  setDownloadedBytes,
  setTotalBytes,
) => {
  const [activeTorrent, setActiveTorrent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const videoRef = useRef(null);

  const clearTorrentData = useCallback(() => {
    setProgress(0);
    setDownloadSpeed(0);
    setUploadSpeed(0);
    setNumPeers(0);
    setRemaining('');
    setDownloadedBytes(0);
    setTotalBytes(0);
    if (videoRef.current) {
      videoRef.current.src = '';
    }
  }, [
    setProgress,
    setDownloadSpeed,
    setUploadSpeed,
    setNumPeers,
    setRemaining,
    setDownloadedBytes,
    setTotalBytes,
  ]);

  useEffect(() => {
    if (!window.WebTorrent || !torrentId) {
      console.error('WebTorrent is not available or no torrentId provided');
      return;
    }

    const client = new window.WebTorrent();
    let intervalId; 

    if (activeTorrent) {
      activeTorrent.destroy();
      clearTorrentData();
    }

    setIsLoading(true);

    const newTorrent = client.add(torrentId, (torrent) => {
      setActiveTorrent(torrent);
      const file = torrent.files.find((file) => file.name.endsWith('.mp4'));

      if (file) {
        const stream = file.createReadStream();
        const chunks = [];

        stream.on('data', (chunk) => {
          chunks.push(chunk);
        });

        stream.on('end', () => {
          const blob = new Blob(chunks, { type: 'video/mp4' });
          if (videoRef.current) {
            videoRef.current.src = URL.createObjectURL(blob);
            videoRef.current.controls = true;
            videoRef.current.muted = true;
            videoRef.current.autoplay = true;
            setIsLoading(false);
          }
        });
      }

      intervalId = setInterval(() => {
        setProgress(Math.round(torrent.progress * 100));
        setDownloadSpeed(torrent.downloadSpeed);
        setUploadSpeed(torrent.uploadSpeed);
        setNumPeers(torrent.numPeers);
        setRemaining(
          torrent.done
            ? 'Done'
            : prettyBytes(torrent.timeRemaining / 1000) + ' remaining',
        );
        setDownloadedBytes(torrent.downloaded);
        setTotalBytes(torrent.length);
      }, 1000);

      return () => {
        clearInterval(intervalId); 
        torrent.removeListener('download');
        torrent.removeListener('upload');
      };
    });

    return () => {
      if (newTorrent) {
        newTorrent.destroy();
      }
      client.destroy();
      clearInterval(intervalId); 
    };
  }, [
    torrentId,
    clearTorrentData,
    setActiveTorrent,
    setIsLoading,
    setProgress,
    setDownloadSpeed,
    setUploadSpeed,
    setNumPeers,
    setRemaining,
    setDownloadedBytes,
    setTotalBytes,
  ]);

  return { isLoading, videoRef };
};

export default useTorrent;
