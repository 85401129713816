import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Typography,
  Box,
  TextField,
  Card,
  Container,
  Snackbar,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useWebRTC } from '../hooks/useWebRTC';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import MuiAlert from '@mui/material/Alert';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const ChatApp = () => {
  const { roomCode } = useParams();

  const [messageText, setMessageText] = useState('');
  const chatContainerRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const {
    messages,
    sendMessage,
    isSendingAttachment,
    sendAttachmentEvent,
    sendStopAttachmentEvent,
    sendTypingEvent,
    sendStopTypingEvent,
    isTyping,
    sendFile,
    isConnected,
  } = useWebRTC(roomCode);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messages, isTyping, isSendingAttachment]);

  const handleSendMessage = () => {
    if (messageText.trim() !== '') {
      sendMessage(messageText);
      sendStopTypingEvent();
      setMessageText('');
      setSelectedFile(null);
    } else if (selectedFile) {
      sendAttachmentEvent();
      sendFile(selectedFile);
      setSelectedFile(null);
    }
  };

  const isCurrentUser = (user) => user === 'you';

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setShowSnackbar(true);
    setSnackbarMessage(`File attached: ${file.name}`);
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  return (
    <Container
      sx={{
        height: '93vh',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden', // Hide outer scrollbar
        ariaLabel: 'Chat application',
      }}
    >
      <Container
        ref={chatContainerRef}
        sx={{
          flexGrow: 1,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto', // Enable inner scrollbar
        }}
        aria-live="polite"
      >
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              mb: 2,
              alignSelf: isCurrentUser(message.senderId)
                ? 'flex-end'
                : 'flex-start',
            }}
          >
            <Card
              elevation={5}
              sx={{
                width: 'fit-content',
                bgcolor: isCurrentUser(message.senderId)
                  ? '#007AFF'
                  : '#FFFFFF',
                color: isCurrentUser(message.senderId) ? '#FFFFFF' : '#000000',
                borderRadius: '12px',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                wordWrap: 'break-word',
              }}
              tabIndex={0}
              role="article"
            >
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                {message.senderId}
              </Typography>
              {message.type === 'file' ? (
                <div>
                  <strong>
                    {message.fileName} ({message.fileType}):
                  </strong>
                  <br />
                  <a
                    href={message.fileData}
                    download={message.fileName}
                    style={{ textDecoration: 'underline', color: 'blue' }}
                  >
                    Download {message.fileName}
                  </a>
                </div>
              ) : (
                <Typography>{message.text}</Typography>
              )}
            </Card>
          </Box>
        ))}

      {isTyping && (
          <Box sx={{ mb: 1, alignSelf: 'flex-start' }}>
            <Card
              elevation={5}
              sx={{
                width: 'fit-content',
                bgcolor: '#FFFFFF',
                color: '#000000',
                borderRadius: '12px',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="caption">User is typing...</Typography>
            </Card>
          </Box>
        )}
        </Container>

      <Box
        sx={{
          borderTop: 1,
          borderColor: 'divider',
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'sticky',
          bottom: 0,
          marginBottom: '10px',
        }}
        role="complementary"
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type your message..."
          sx={{ mr: 2 }}
          onChange={(e) => {
            setMessageText(e.target.value);
            if (e.target.value.trim() !== '') {
              sendTypingEvent();
            } else {
              sendStopTypingEvent();
            }
          }}
          onBlur={() => {
            sendStopTypingEvent();
          }}
          value={messageText}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSendMessage();
            } else if (e.key === 'Backspace' && messageText.trim() === '') {
              sendStopTypingEvent();
            }
          }}
          aria-label="Type your message"
          autoComplete="off"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendMessage}
          aria-label="Send message"
          startIcon={<SendIcon />}
        >
          {selectedFile ? 'Send Attachment' : 'Send'}
        </Button>
        <input
          ref={fileInputRef}
          type="file"
          accept=".png, .txt, .jpg, .jpeg, .gif"
          onChange={handleFileSelect}
          hidden
          aria-label="Select a file to attach"
        />
        <Button
          variant="outlined"
          color="inherit"
          component="label"
          sx={{
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            marginLeft: '8px',
          }}
          onClick={() => fileInputRef.current.click()}
          aria-label="Attach a file"
        >
          <AttachFileIcon />
        </Button>
        <FiberManualRecordIcon
          sx={{
            color: isConnected ? 'green' : 'red',
            marginLeft: 1,
            fontSize: '1rem',
          }}
          aria-label={isConnected ? 'Online' : 'Offline'}
        />
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity="info"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default ChatApp;
