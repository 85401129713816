import React, { useState } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import JoinRoom from './JoinRoom';
import TorrentPlayer from './TorrentPlayer';
import torrentData from '../config/torrents';
import { prettyBytes } from '../transformers/prettyBytes';

// Feature component for consistency
const Feature = ({ icon, title, description }) => (
  <div className="flex mb-4">
    <span className="text-4xl mr-4">{icon}</span>
    <div>
      <h3 className="text-xl font-semibold">{title}</h3>
      <p className="text-md">{description}</p>
    </div>
  </div>
);

const Home = () => {
  const [progress, setProgress] = useState(0);
  const [downloadSpeed, setDownloadSpeed] = useState(0);
  const [uploadSpeed, setUploadSpeed] = useState(0);
  const [numPeers, setNumPeers] = useState(0);
  const [remaining, setRemaining] = useState('');
  const [downloadedBytes, setDownloadedBytes] = useState(0);
  const [totalBytes, setTotalBytes] = useState(0);
  const [currentTorrentId, setCurrentTorrentId] = useState(torrentData[0].url);

  return (
    <div className="flex flex-col min-h-screen px-10 lg:px-20 mt-5">
      <div className="flex-grow">
        {/* Main Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center mx-auto py-10">
          <div>
            <JoinRoom />
          </div>
          <div className="shadow-2xl rounded-3xl overflow-hidden max-w-sm mx-auto md:max-w-none">
            <img
              src="https://images.purevpn-tools.com/public/images/webrtc-logo.png"
              alt="ChatVanish"
              width={500}
              height={300}
              className="w-full h-auto"
            />
            <div className="p-6 bg-white dark:bg-gray-800">
              <h2 className="scroll-m-20 text-2xl tracking-tight text-2xl mb-1 dark:text-white">
                Prioritizing Your Privacy
              </h2>
              <p className="leading-7 [&:not(:first-child)]:mt-6text-md font-light text-gray-600 dark:text-gray-300">
                At ChatVanish, we meticulously craft every detail to ensure your
                conversations remain private and secure.
              </p>
            </div>
          </div>
        </div>

        {/* "Key Features" Section */}
        <div className="mb-10">
          <div className="shadow-2xl rounded-3xl p-10 pb-0 bg-white dark:bg-gray-800">
            <h2 className="text-5xl font-bold leading-tight tracking-tighter text-center mb-10">
              Key Features
            </h2>
            <div className="leading-7 [&:not(:first-child)]:mt-6 grid grid-cols-1 md:grid-cols-2 gap-4 pb-6">
              <Feature
                icon="🔑"
                title="Room ID Generation"
                description="Enter a unique ID to start or join a chat room."
              />
              <Feature
                icon="🛡️"
                title="Secure Encryption"
                description="Your messages are fully secured with end-to-end encryption."
              />
              <Feature
                icon="🌐"
                title="Global Connectivity"
                description="Easily access your chat rooms from anywhere worldwide."
              />
              <Feature
                icon="🔒"
                title="Ephemeral Chats"
                description="Messages vanish post-conversation, ensuring privacy."
              />
              <Feature
                icon="🚹"
                title="Identity Control"
                description="Opt for anonymity or personalize your user profile."
              />
              <Feature
                icon="📁"
                title="Secure File Sharing"
                description="Confidently share files within your secure chat space."
              />
            </div>
          </div>
        </div>

        {/* Torrent Player Section */}
        <div className="shadow-2xl rounded-3xl p-10 bg-white dark:bg-gray-800 my-10">
          <h2 className="text-5xl font-bold leading-tight tracking-tighter text-center">
            Receiver Centric
          </h2>
          <p className="text-lg text-muted-foreground text-center mb-4">
            Bring your own files and leave with everyone else's
          </p>

          {/* Dropdown or buttons to select torrent */}
          <div className="torrent-selector flex justify-center gap-2 my-4">
            {torrentData.map((torrent, index) => (
              <button
                key={index}
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
                  currentTorrentId === torrent.url ? 'active-class' : ''
                }`}
                onClick={() => setCurrentTorrentId(torrent.url)}
              >
                {torrent.name}{' '}
                {currentTorrentId === torrent.url ? '(Playing)' : ''}
              </button>
            ))}
          </div>

          <TorrentPlayer
            key={currentTorrentId}
            torrentId={currentTorrentId}
            setProgress={setProgress}
            setDownloadSpeed={setDownloadSpeed}
            setUploadSpeed={setUploadSpeed}
            setNumPeers={setNumPeers}
            setRemaining={setRemaining}
            setDownloadedBytes={setDownloadedBytes}
            setTotalBytes={setTotalBytes}
          />
          <div className="text-md torrent-info text-center mt-2">
            <div>
              Progress: {prettyBytes(downloadedBytes)} /{' '}
              {prettyBytes(totalBytes)}
            </div>
            <div>Download Speed: {prettyBytes(downloadSpeed)}/s</div>
            <div>Upload Speed: {prettyBytes(uploadSpeed)}/s</div>
            <div>Peers: {numPeers}</div>
            {/* ... other details ... */}
          </div>
        </div>

        {/* "How It Works" Section */}
        <div className="shadow-2xl rounded-3xl p-10 bg-white dark:bg-gray-800 mt-5 mb-2">
          <h2 className="text-5xl font-bold leading-tight tracking-tighter text-center mb-10">
            How It Works
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
            <div className="leading-7 flex flex-col mb-8 p-6 rounded-xl shadow-lg max-w-md mx-auto h-full justify-center">
              <span className="text-3xl mb-4 inline-block">📡</span>
              <h4 className="text-2xl font-semibold mb-3">Signal Server</h4>
              <p className="text-lg text-gray-600">
                The Signal Server connects users for direct, secure
                communication.
              </p>
            </div>
            <div className="flex flex-col mb-8 p-6 rounded-xl shadow-lg max-w-md mx-auto h-full justify-center">
              <span className="text-3xl mb-4 inline-block">↔️</span>
              <h4 className="text-2xl font-semibold mb-3">Peer Connection</h4>
              <p className="text-lg text-gray-600">
                After introduction, users communicate directly, akin to
                face-to-face.
              </p>
            </div>
            <div className="flex flex-col mb-8 p-6 rounded-xl shadow-lg max-w-md mx-auto h-full justify-center">
              <span className="text-3xl mb-4 inline-block">🔒</span>
              <h4 className="text-2xl font-semibold mb-3">Secure Exchange</h4>
              <p className="text-lg text-gray-600">
                All data exchanged is encrypted for your privacy and security.
              </p>
            </div>
          </div>
        </div>

        {/* Footer */}
        <footer className="py-4">
          <div className="container mx-auto text-center">
            <p className="text-sm text-muted-foreground">
              Made with{' '}
              <span className="text-red-500">
                <FavoriteIcon className="text-red-500 inline-block" />
              </span>{' '}
              by ChatVanish
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Home;
