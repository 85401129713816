import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container, CssBaseline } from '@mui/material';
import Home from './components/Home';
import ChatApp from './components/ChatApp';
import useAuth from './hooks/useAuth';
import { SiteHeader } from './components/site-header';
import { ThemeProvider } from './components/theme-provider';
import { ClerkProvider, ClerkLoaded } from '@clerk/clerk-react';

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const App = () => {
  const { user } = useAuth();

  return (
    
    <ClerkProvider publishableKey={clerkPubKey}>
      <ClerkLoaded>
        <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
          <Router>
            <SiteHeader />
              <Routes>
                <Route path="/" element={<Home user={user} />} />
                <Route
                  path="/chat/:roomCode"
                  element={<ChatApp user={user} />}
                />
              </Routes>
          </Router>
        </ThemeProvider>
      </ClerkLoaded>
    </ClerkProvider>
  );
};

export default App;
