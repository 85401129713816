const initialState = {
    roomCode: null
};

const roomReducer = (state = initialState, action) => {
    console.log('Reducer action type:', action.type);
    switch (action.type) {
        case 'SET_ROOM_CODE':
            console.log('Setting room code in reducer:', action.payload); 
            return { ...state, roomCode: action.payload };
        default:
            return state;
    }
};

export default roomReducer;
