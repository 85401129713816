'use client';
import { siteConfig } from '../config/site';
import { MainNav } from './main-nav';
import { ThemeToggle } from './theme-toggle';
import { UserButton, useUser, useClerk } from '@clerk/clerk-react';
import React from 'react';
import { Button } from './ui/button';

export function SiteHeader() {
  const { isSignedIn } = useUser();
  const { openSignIn } = useClerk();

  const handleLoginClick = () => {
    openSignIn();
  };

  return (
    <header className="bg-blue-600 sticky top-0 z-40 w-full border-b border-blue-700">
      <div className="container flex h-16 items-center justify-between">
        {/* Left Placeholder for Symmetry */}
        <div className="flex-1"></div>

        {/* Center Container for ChatVanish and Logo */}
        <div className="flex-1 flex justify-center items-center">
          <MainNav items={siteConfig.mainNav} />
        </div>

        {/* Right Container for ThemeToggle and Sign-in */}
        <div className="flex-1 flex justify-end items-center text-white">
          <nav className="flex items-center space-x-1">
            <ThemeToggle />
            {isSignedIn ? (
              <UserButton afterSignOutUrl="/" />
            ) : (
              <Button
                onClick={handleLoginClick}
                className="bg-transparent text-white border-white border px-4 py-2 rounded-lg hover:bg-white hover:text-blue-600 focus:outline-none"
              >
                Login
              </Button>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
}
